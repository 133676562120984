import { getOrCreateDataObject } from "o365-dataobject";
import { getOrCreateProcedure } from "o365-modules";

export default class TagRecognizer {
    readonly recognizerOCR: RecognizerOCR;
    readonly sessionId: number;
    readonly documentId: number
    readonly apryseSizes: {
        w: number,
        h: number,
    };
    readonly rotation: PageRotation;

    patterns: ParsedPattern[];

    depth = 2;

    constructor(pText: TextOCR, pPatterns: Pattern[], pDocumentId: number, pApryseSizes: { w: number, h: number }, pRotation: PageRotation) {
        if (typeof pText == 'string') {
            pText = JSON.parse(pText);
        }

        this.rotation = pRotation;
        this.apryseSizes = pApryseSizes;

        const parsedPatters: ParsedPattern[] = [];

        for (const pattern of pPatterns) {
            parsedPatters.push({
                $value: pattern.value,
                regex: this.getRegexFromPattern(pattern.value),
                partials: this.getRegexPartialsFromPattern(pattern.value)
            })
        }

        this.patterns = parsedPatters;

        this.sessionId = getSessionId();
        this.documentId = pDocumentId;

        this.recognizerOCR = new RecognizerOCR(pText, this);
    }

    async getMatches() {
        const potentialMatches = this.getPotentialMatches();

        if (potentialMatches.length == 0) {
            return [];
        }

        const dataObject = this._getObjectMatchesDataObject();
        await dataObject.recordSource.bulkCreate(potentialMatches.map((tag) => {
            return {
                Tag: tag.name,
                Session_ID: this.sessionId,
                Position: tag.position
            }
        }));

        const result = await (this._getObjectConnectProc()).execute({ Session_ID: this.sessionId, Document_ID: this.documentId, CreateAnnots: true });
        const tags = result.Table as { Tag: string, Object_ID?: number }[];
        return tags;
    }

    getPotentialMatches() {
        let result: {
            name: string,
            lines: OCRLine[]
            position?: string
        }[] = [];

        const matches = [];
        const groups = new Map<string, PotentialTag[]>();

        for (const line of this.recognizerOCR.lines) {
            const tags = line.getPotentialTags();
            for (const tag of tags) {
                matches.push(tag);
            }
        }

        for (const match of matches) {
            if (!/\d/.test(match.text)) { continue; }
            const firstLine = match.lines[0];
            const position = `${firstLine.topLeft.x},${firstLine.topLeft.y},${firstLine.bottomRight.x},${firstLine.bottomRight.y}`;
            const key = `t:${firstLine.text};p:${position};`;
            if (!groups.has(key)) {
                groups.set(key, []);
            }

            groups.get(key)!.push(match);
        }

        for (const entry of groups) {
            const tag = entry[1].reduce((maxTag, tag) => {
                if (maxTag == null || tag.lines.length > maxTag.lines.length) {
                    maxTag = tag;
                }
                return maxTag;
            });
            result.push({
                name: tag.text,
                lines: tag.lines,
                position: tag.position
            });
        }

        return result
    }

    getRegexFromPattern(pPattern: string) {
        const regexPattern = pPattern
            .replace(/%/g, '[a-zA-Z0-9]')
            .replace(/#/g, '[0-9]')
            .replace(/ /g, '\\s')  
            .replace(/-/g, '\-');   

        this.depth = Math.max(this.depth, regexPattern.split('\\s').length);        
        
        return new RegExp(`^${regexPattern}$`);
    }

    getRegexPartialsFromPattern(pPattern: string) {
        const regexPattern = pPattern
            .replace(/%/g, '[a-zA-Z0-9]')
            .replace(/#/g, '[0-9]')
            .replace(/ /g, '\\s')  
            .replace(/-/g, '\-');   

        return regexPattern.split('\\s').map((pattern) => {
            return new RegExp(`^${pattern}$`);
        });
    }


    private _getObjectMatchesDataObject() {
        const dataObject = getOrCreateDataObject({
            id: 'o_dsObjectMatches',
            viewName: 'atbv_Arena_DocumentsObjectsMatches',
            fields: [
                { name: 'Tag' },
                { name: 'Object_ID' },
                { name: 'Session_ID' }
            ],
        });
        dataObject.recordSource.whereClause = `[Session_ID] = ${this.sessionId}`;

        return dataObject;
    }

    private _getObjectConnectProc() {
        const proc = getOrCreateProcedure<{ Session_ID: number, Document_ID: number, CreateAnnots?: boolean }>({
            id: 'procConnectObjectTagMatches',
            procedureName: 'astp_Arena_ConnectObjectTagMatches'
        });

        return proc;
    }
}

type Pattern = {
    value: string
};

type ParsedPattern = {
    $value: string,
    regex: RegExp
    partials: RegExp[]
}

class RecognizerOCR {
    $source: TagRecognizer;
    $text: TextOCR;
    lines: OCRLine[] = [];
    constructor(pOCR: TextOCR, pSource: TagRecognizer) {
        this.$source = pSource;
        this.$text = pOCR;

        let i = 0;
        for (const line of pOCR.Lines) {
            if (line.BoundingPolygon == null) {
                convertPolygonToBoundingPolygon(line);
            }

            line.Content = line.Content.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            this.lines.push(new OCRLine(line, this, i++));
        }
    }
}

class OCRLine {
    readonly $line: TextOCR['Lines'][number];
    readonly $source: RecognizerOCR;
    readonly index: number;

    get text() { return this.$line.Content; }
    get position() { return this.$line.BoundingPolygon; }

    get topLeft() { return { x: this.$line.BoundingPolygon[0].X, y: this.$line.BoundingPolygon[0].Y }; }
    get topRight() { return { x: this.$line.BoundingPolygon[1].X, y: this.$line.BoundingPolygon[1].Y }; }
    get bottomRight() { return { x: this.$line.BoundingPolygon[2].X, y: this.$line.BoundingPolygon[2].Y }; }
    get bottomLeft() { return { x: this.$line.BoundingPolygon[3].X, y: this.$line.BoundingPolygon[3].Y }; }

    get patterns() { return this.$source.$source.patterns; }
    get maxLength() { return Math.max(...this.patterns.map((p) => p.$value.length)); }

    constructor(pLine: TextOCR['Lines'][number], pSource: RecognizerOCR, pIndex: number) {
        this.$source = pSource;
        this.$line = pLine;
        this.index = pIndex;
    }


    getPotentialTags() {
        if (!this.isPartialMatch(this)) { return []; }

        const maxLines = this.$source.lines.length;
        const maxLength = this.maxLength;
        const maxDepth = this.$source.$source.depth;
        const matches: PotentialTag[] = [];

        const tags = [new PotentialTag(this)];

        const matchTag = (pTag: PotentialTag) => {
            if (this.isPotentialTag(pTag)) {
                matches.push(pTag.copy());
            }
        };

        matchTag(tags[0]);

        for (let i = this.index + 1; i < maxLines; i++) {
            const nextTag = this.$source.lines[i];
            if (nextTag) {
                const newTags = [];
                for (const tag of tags) {
                    if ((tag.text + nextTag.text).length <= maxLength && tag.isAdjacent(nextTag) && this.isPartialMatch(nextTag)) {
                        const newTag = tag.copy();
                        newTag.lines.push(nextTag);
                        newTags.push(newTag);
                    }
                }

                for (const tag of newTags) {
                    if (tag.lines.length <= maxDepth) {
                        tags.push(tag);
                        matchTag(tag);
                    }
                }
            }
        }

        return matches;
    }

    isPartialMatch(pTag: OCRLine) {
        const patterns = this.patterns;
        const text = pTag.text;
        for (const pattern of patterns) {
            for (const partial of pattern.partials) {
                if (partial.test(text)) {
                    return true;
                }
            }
        }
        return false;
    }

    isPotentialTag(pTag: PotentialTag) {
        const patterns = this.patterns;
        const text = pTag.text;
        for (const pattern of patterns) {
            if (pattern.regex.test(text)) {
                return true;
            }
        }
        return false;
    }

}

class PotentialTag {
    lines: OCRLine[] = [];
    get text() { return this.lines.map((l) => l.text).join(' '); }
    get position() {
        if (this.lines[0].$source.$source.rotation == PageRotation.Rotation0 && false) {
            const padding = 3;
            const start = this.lines.at(0)!;
            const end = this.lines.at(-1)!;
            const azureSizes = { w: start.$source.$text.Width, h: start.$source.$text.Height }
            const apryseSizes = start.$source.$source.apryseSizes;
            const topLeft = convertPosition(start.topLeft, azureSizes, apryseSizes);
            const bottomRight = convertPosition(end.bottomRight, azureSizes, apryseSizes);
            return `${topLeft.x - padding},${topLeft.y + padding},${bottomRight.x + padding},${bottomRight.y - padding}`;
        }
        let minX: number = -1;
        let minY: number = -1;
        let maxX: number = -1;
        let maxY: number = -1;

        for (const line of this.lines) {
            if (minX == -1) {
                minX = line.topLeft.x;
            }
            if (minY == -1) {
                minY = line.topLeft.y;
            }
            if (maxX == -1) {
                maxX = line.topLeft.x;
            }
            if (maxY == -1) {
                maxY = line.topLeft.y;
            }

            // top-left
            minX = Math.min(line.topLeft.x, minX);            
            minY = Math.min(line.topLeft.y, minY);            
            // top-right
            maxX = Math.max(line.topRight.x, maxX);            
            minY = Math.min(line.topRight.y, minY);            
            // bottom-right
            maxX = Math.max(line.bottomRight.x, maxX);            
            maxY = Math.max(line.bottomRight.y, maxY);          
            // bottom-left
            minX = Math.min(line.bottomLeft.x, minX);            
            maxY = Math.max(line.bottomLeft.y, maxY);            
        }

        const sourceLine = this.lines.at(0)!;
        const padding = (sourceLine.bottomLeft.y - sourceLine.topLeft.y) / 3;

        minX -= padding;
        minY -= padding;
        maxX += padding;
        maxY += padding;

        const azureSizes = { w: sourceLine.$source.$text.Width, h: sourceLine.$source.$text.Height }
        const apryseSizes = sourceLine.$source.$source.apryseSizes;
        const rotation = sourceLine.$source.$source.rotation;
        const rect = getRect({
            topLeft: { x: minX, y: minY },
            topRight: { x: maxX, y: minY },
            bottomRight: { x: maxX, y: maxY },
            bottomLeft: { x: minX, y: maxY },
        }, azureSizes, apryseSizes, rotation);
        return `${rect.topLeft.x},${rect.topLeft.y},${rect.bottomRight.x},${rect.bottomRight.y}`;
    }

    constructor(pLine: OCRLine) {
        this.lines.push(pLine);
    }

    isAdjacent(pLine: OCRLine) {
        const start = this.lines.at(0)!;
        const isNormal = start.$source.$source.rotation == PageRotation.Rotation0 && false; 
        const isLTR = isNormal
            ?pLine.topLeft.x > start.topLeft.x && pLine.topLeft.y > start.topLeft.y
            : pLine.topLeft.x > start.topLeft.x || pLine.topLeft.y > start.topLeft.y;
        if (!isLTR) { return false; }

        const last = this.lines.at(-1)!;
        // const threshold = 0.25;
        const threshold = isNormal
            ? 0.25
            : (pLine.bottomLeft.y - pLine.topLeft.y) * 1.25;
        const x = Math.abs(pLine.topLeft.x - last.bottomRight.x);
        const y = Math.abs(pLine.topLeft.y - last.bottomRight.y);

        const x2 = Math.abs(pLine.topRight.x - last.bottomRight.x);
        const y2 = Math.abs(pLine.topRight.y - last.bottomRight.y);
 
        const isAdjacent = x <= threshold && y <= threshold || x2 <= threshold && y2 <= threshold;

        // const isAdjacent = this.lines.some((line) => {
        //     const threshold = 0.25;
        //     const x = pLine.topLeft.x - line.bottomRight.x;
        //     const y = pLine.topLeft.y - line.bottomRight.y;
        //     return x <= threshold && y <= threshold;
        // });

        if (!isAdjacent) { return false; }

        return true;
    }


    copy() {
        const lines = [...this.lines];
        const tag = new PotentialTag(lines.splice(0, 1)[0]);
        for (const line of lines) {
            tag.lines.push(line);
        }

        return tag;
    }
}

function getRect(pPoints: {
    topLeft: { x: number, y: number },
    topRight: {x: number, y: number },
    bottomRight: { x: number, y: number },
    bottomLeft: { x: number, y: number }
}, pAzureSizes: { w: number, h: number }, pApryseSizes: { w: number, h: number, }, pRotation: PageRotation) {
    if (pAzureSizes && pApryseSizes) {
        const normalizeX = (pX: number, pInverse = false) => pInverse
            ? Math.floor(Math.abs(pAzureSizes.w - pX) * pApryseSizes.w / pAzureSizes.w * 1000) / 1000
            : Math.floor(pX * pApryseSizes.w / pAzureSizes.w * 1000) / 1000;
        const normalizeY = (pY: number, pInverse = false) => pInverse
            ? Math.floor(Math.abs(pAzureSizes.h - pY) * pApryseSizes.h / pAzureSizes.h * 1000) / 1000
            : Math.floor(pY * pApryseSizes.h / pAzureSizes.h * 1000) / 1000;

        if (pRotation == PageRotation.Rotation0) {
            return {
                topLeft: {
                    x: normalizeX(pPoints.topLeft.x),
                    y: normalizeY(pPoints.topLeft.y, true)
                },
                topRight: {
                    x: normalizeX(pPoints.topRight.x),
                    y: normalizeY(pPoints.topRight.y, true)
                },
                bottomRight: {
                    x: normalizeX(pPoints.bottomRight.x),
                    y: normalizeY(pPoints.bottomRight.y, true)
                },
                bottomLeft: {
                    x: normalizeX(pPoints.bottomLeft.x),
                    y: normalizeY(pPoints.bottomLeft.y, true)
                },
            }
        } else if (pRotation == PageRotation.Rotation90) {
            return {
                topLeft: {
                    x: normalizeX(pPoints.bottomLeft.y),
                    y: normalizeY(pPoints.bottomLeft.x)
                },
                topRight: {
                    x: normalizeX(pPoints.topLeft.y),
                    y: normalizeY(pPoints.topLeft.x)
                },
                bottomRight: {
                    x: normalizeX(pPoints.topRight.y),
                    y: normalizeY(pPoints.topRight.x,)
                },
                bottomLeft: {
                    x: normalizeX(pPoints.bottomRight.y,),
                    y: normalizeY(pPoints.bottomRight.x)
                },
            }
        } else if (pRotation == PageRotation.Rotation180) {
            return {
                topLeft: {
                    x: normalizeX(pPoints.topLeft.x),
                    y: normalizeY(pPoints.topLeft.y, true)
                },
                topRight: {
                    x: normalizeX(pPoints.topRight.x),
                    y: normalizeY(pPoints.topRight.y, true)
                },
                bottomRight: {
                    x: normalizeX(pPoints.bottomRight.x),
                    y: normalizeY(pPoints.bottomRight.y, true)
                },
                bottomLeft: {
                    x: normalizeX(pPoints.bottomLeft.x),
                    y: normalizeY(pPoints.bottomLeft.y, true)
                },
            }
        } else if (pRotation == PageRotation.Rotation270) {
            return {
                topLeft: {
                    x: normalizeY(pPoints.bottomRight.y, true),
                    y: normalizeX(pPoints.bottomRight.x, true)
                },
                topRight: {
                    x: normalizeY(pPoints.topRight.y, true),
                    y: normalizeX(pPoints.topRight.x, true)
                },
                bottomRight: {
                    x: normalizeY(pPoints.topLeft.y, true),
                    y: normalizeX(pPoints.topLeft.x, true)
                },
                bottomLeft: {
                    x: normalizeY(pPoints.bottomLeft.y, true),
                    y: normalizeX(pPoints.bottomLeft.x, true)
                },
            }
            // return {
            //     topLeft: rotatePoint(pPoints.bottomRight.x, pPoints.bottomRight.y),
            //     topRight: rotatePoint(pPoints.bottomRight.x, pPoints.bottomRight.y),
            //     bottomRight: rotatePoint(pPoints.topLeft.x, pPoints.topLeft.y),
            //     bottomLeft: rotatePoint(pPoints.topLeft.x, pPoints.topLeft.y),
            // }
        } else {
            return pPoints;
        }
    }
    else {
        return pPoints;
    }
}


function convertPosition(pPosition: { x: number, y: number }, pAzureSizes: { w: number, h: number }, pApryseSizes: { w: number, h: number, }) {
    if (pAzureSizes && pApryseSizes) {
        const x = Math.floor((pPosition.x * pApryseSizes.w / pAzureSizes.w) * 1000) / 1000;
        const y = Math.floor((Math.abs((pAzureSizes.h - pPosition.y)) * pApryseSizes.h / pAzureSizes.h) * 1000) / 1000;
        return { x, y };
    } else {
        return pPosition;
    }
}

/**
 * Get or create an UID for the Object atbl_Arena_DocumentsObjectsMatches
 */
function getSessionId() {
    const max = 2147483647;
    const UID = Math.floor(Math.random() * (max + 1));
    return UID;
}

function convertPolygonToBoundingPolygon(pPart: TextOCRPart) {
    if (pPart.BoundingPolygon != null) { return; }
    pPart.BoundingPolygon = [];
    const polygons = [...pPart.Polygon];
    for (let i = 0; i < polygons.length; i += 2) {
        const boundingPolygon = { X: polygons[i], Y: polygons[i + 1], IsEmpty: false};
        pPart.BoundingPolygon.push(boundingPolygon);
    }
}

type TextOCR = {
    Unit: number,
    PageNumber: number,
    Width: number,
    Height: number,
    Spans: { Index: number, Length: number}[]
    Words: TextOCRPart[],
    Lines: TextOCRPart[]
};

type TextOCRPart = {
    BoundingPolygon: { IsEmpty: boolean, X: number, Y: number }[]
    Polygon: number[]
    Content: string,
    Span: { Index: number, Length: number },
    Confidence: number
};

enum PageRotation {
    Rotation0,
    Rotation90,
    Rotation180,
    Rotation270
};

export { TagRecognizer }; 